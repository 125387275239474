import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"
import SEO from "../components/SEO"

import solomons1987 from "../images/history/solomon_motors_1987.jpg"
import solomontoyota from "../images/history/solomon_toyota.jpg"

import "./our-history.scss"

const OurHistory = (props) => (
  <Layout location={props.location}>
    <SEO
      title="Our History"
      description="Solomon Motors commenced business back in 1971 building a fantastic
      reputation for selling quality motor vehicles."
    />
    <Heading title="Our History" />
    <div className="row justify-content-center">
      <div className="col-lg-8 col-md-12 text-center">
        <p>
          Solomon Motors commenced business back in 1971 building a fantastic
          reputation for selling quality motor vehicles.
        </p>
        <p>
          In 1987, Solomon Motors moved to a new premises on the corner of
          Quinns Lane and Princes Highway South Nowra where they developed a
          purpose built motor dealership to cater for a large volume of quality
          used motor vehicles and is one of the largest south of Sydney.
        </p>
        <p>
          In 1999, Solomons once again expanded their operation and started a
          second dealership on the Princes Hwy Central Nowra called Solomons Mid
          City Autos forming the Solomon Motor Group.
        </p>
        <p>
          Solomon Motors has been in the area for over 53 years priding
          themselves in supplying quality used vehicles and great customer
          service to its new and existing customers. Still to this day, Solomon
          Motor Group is owned and operated by the Solomon family.
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <img
              src={solomontoyota}
              className="history-image"
              alt="Solomon Toyota on Berry Street 1987"
            />
            <p className="history-image-caption">
              Solomon Toyota in Berry Street
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <img
              src={solomons1987}
              className="history-image"
              alt="Solomon Motors Opening Day 1987"
            />
            <p className="history-image-caption">
              Solomon Motors Opening Day 1987
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default OurHistory
